
module.exports = {
    getAccess: (req) => {
        const ACCESS = "ACCESS_GRANTED"
        const RESTRICTED = 'ACCESS_DENIED'

        switch (req) {
            case "mbtDjpzW5NUuRGavOjzCNFDIeSm2": // Brittany
                return ACCESS

            case "kHxXfKf76wZSDhFtul6kLMHkRvm1": // Chris
                return ACCESS

            default:
                return RESTRICTED
        }

    }
}