import styled, { css } from 'styled-components';

export const AdminPrototype = styled.section`
width:100%;
min-height:300px;
margin:auto;
padding:20px;
display:flex;
flex-direction:column;
justify-content:space-around;
`