import styled from 'styled-components'

export const AdminBox = styled.main`
    min-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const AdminHeader = styled.header`
    display:flex;

    h4 {
        font-size:16px;
        font-weight:200;
    }
`